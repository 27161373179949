import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as auth from '../../services/auth'
import * as api from '../../services/api'
import * as qbd from '../../services/qbd'
import * as Account from '../../account';
import '../../index.css';
import Xarrow from "react-xarrows";
import flat from 'flat'
import { sortOnKeys, locale } from "../../utils";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
export default function Jobs(props) {

    const [selectedItems, setSelectedItems] = useState({
        customer: false,
        product: false,
        order: false,
        group: false,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setSelectedItems(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    var account = Account.getUserAccount()
    const [errorMessage, setErrorMessage] = useState("");
    const [orderSelector, setOrderSelector] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedOrderType, setSelectedOrderType] = useState("0");
    const [order, setOrder] = useState([]);
    const [orderMessage, setOrderMessage] = useState("");
    const [QBDCompany, setQBDCompany] = useState(null);
    const [foraWorks, setForaWorks] = useState(false);
    const [resyncOrder, setResyncOrder] = useState([]);
    const [foraCompany, setForaCompany] = useState(null);

    const checkForaWorks = () => {
        api.getHealth()
            .then(response => {
                setForaWorks(response.success)
                if (response.model)
                    setForaCompany(response.model)
            })
            .catch(err => {
                setForaWorks(false)
            }).finally(_ => {

            })
    }

    const connectQuickBooksDesktop = () => {
        setQBDCompany(null)
        qbd.connect().then(response => {
            setQBDCompany(response)
        }).catch(err => {
            if (err && err.data && err.data.error)
                setErrorMessage(err.data.error)
            console.log(err)
        }).finally(_ => {

        })
    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingLog, setLoadingLog] = useState(false);
    let timerId;
    const runJob = () => {

        checkForaWorks()
        if (!foraWorks) return
        connectQuickBooksDesktop()
        if (!QBDCompany) return


        const options = Object.keys(selectedItems).filter((key) => selectedItems[key])
        if (options.length == 0) return
        setLoading(true)
        api.getSettingFora()
            .then(response => {
                var acc = Account.getUserAccount();
                var data = {
                    Options: options,
                    JWTToken: acc.access_token,
                    AppID: response.appID,
                    AppSecret: response.secret,
                    ResyncOrder: resyncOrder,
                    ResyncOrderID: "",
                    ResyncOrderKey: "",
                }
                qbd.sync(data)
                    .then(res => {
                        //console.log(res)
                    })
                    .catch(err => {
                        if (err && err.data && err.data.error)
                            setErrorMessage(err.data.error)
                        console.log(err)
                    }).finally(_ => {
                        setResyncOrder([]);
                        options.forEach(function (job) {
                            setSelectedItems(prevState => ({
                                ...prevState,
                                [job]: false,
                            }));
                        })
                        setLoading(false)
                        setLoadingLog(true)
                        timerId = setTimeout(logs, 5000);
                    })
            })
            .catch(err => {
                if (err && err.data)
                    alert(err.data.error)

                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            })



    }

    const runJobState = () => {
        api.runJobState()
            .then(response => {
                setData(response)
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                clearTimeout(timerId);
            })
    }

    const logs = () => {
        var data = {
            platform: "quickbooks_desktop",
        }
        setLoadingLog(true)
        api.logs(data)
            .then(response => {
                setData(response)
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                setLoadingLog(false)
            })
    }


    const handleTabClose = event => {
        event.preventDefault();
        return (event.returnValue = 'Are you sure you want to exit?');
    };


    useEffect(() => {
        //const interval = setInterval(logs, 10000);
        logs()
        checkForaWorks()
        connectQuickBooksDesktop()

        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };

    }, [])

    const fetchOrder = () => {
        setLoading(true)
        setOrderMessage("")
        api.foraOrdersOrQuotes(selectedOrderType, selectedDate ? format(selectedDate, 'yyyy-MM-dd') : "")
            .then(response => {

                if (response && response.message)
                    setOrderMessage(response.message)

                if (response && response.model)
                    setOrder(response.model)
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                setLoading(false)
            })

    }

    const parseStatus = (item) => {
        var data = JSON.parse(item)

        if (!data) return "FAILED";

        var result = data.result

        if (!result) return "FAILED"

        if (result.order)
            return result.order.error_message ? "FAILED" : data.status

        if (data.job == "order" && (!result.synced || result.synced.length == 0)) return "FAILED";

        if (result.synced && Array.isArray(result.synced))
            return result.synced.map(t => t.Error ? "FAILED" : data.status).join(',').split(",")[0]


        return data.status

    }

    const canResync = (item) => {
        var data = JSON.parse(item)

        if (!data) return false

        var result = data.result

        if (!result) return false

        if (result.order) {
            return result.order.Key && result.order.SaleOrderNumber && !resyncOrder.some(obj => obj.SaleOrderNumber === result.order.SaleOrderNumber)
        }

    }

    const resync = (item) => {
        var data = JSON.parse(item)

        if (!data) return;

        var result = data.result

        if (!result) return;

        if (result.order) {
            if (result.order.Key && result.order.SaleOrderNumber) {
                if (!resyncOrder.some(obj => obj.SaleOrderNumber === result.order.SaleOrderNumber))
                    setResyncOrder([...resyncOrder, { Key: result.order.Key, SaleOrderNumber: result.order.SaleOrderNumber }])

                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth'
                // });
            }
        }
    }

    const selectOrder = (SaleOrderNumber, Key) => {
        if (!resyncOrder.some(obj => obj.SaleOrderNumber === SaleOrderNumber))
            setResyncOrder([...resyncOrder, { Key: Key, SaleOrderNumber: SaleOrderNumber }])
    }


    const removeObject = (SaleOrderNumber) => {
        setResyncOrder(resyncOrder.filter((c) => c.SaleOrderNumber !== SaleOrderNumber));
    };

    useEffect(() => {

        var doing = false
        if (resyncOrder.length > 0)
            doing = true

        setSelectedItems({
            customer: false,
            product: false,
            group: false,
            order: doing,
        });

    }, [resyncOrder])


    const parseResult = (item) => {
        var data = JSON.parse(item)

        if (!data) return "";

        var result = data.result

        if (!result) return `No new ${data.job}s to sync`;


        if (result.order)
            return result.order.Order + (result.order.error_message ? " import failed. " : ` imported successfully to ${result.order.Company ? result.order.Company.Name + " - " : ""} ${result.order.Customer ? result.order.Customer.Name + "'s" : ""} ${result.order.RefNumber}.`) + (result.order.error_message ? result.order.error_message : "")

        if (result.synced && Array.isArray(result.synced))
            return result.synced.map(t => "Fora " + (t.ForaOrder || t.Quote || t.Order || t.Group)
                + (t.QBOrder ? " successfully import to\nQuickBooks Desktop. QuickBooks " + t.QBOrder.DocNumber : (t.Error ? " failed to import to QuickBooks Desktop" : "")) + (t.error_message ? ` : ${t.error_message}` : "")).join('\n\n')


        if (result.fora_response) {
            try {
                return JSON.parse(result.fora_response).Message
            } catch {
                return result.fora_response
            }
        }

        return result.error_message
    }


    function formatDate(dbDateString) {

        // Create a new Date object with the database date string
        const dbDate = new Date(dbDateString);

        // Get the machine timezone offset in minutes
        const timezoneOffset = new Date().getTimezoneOffset();

        // Convert the timezone offset to milliseconds
        const timezoneOffsetMs = timezoneOffset * 60 * 1000;

        // Get the machine date by adding the timezone offset to the database date
        const machineDate = new Date(dbDate.getTime() - timezoneOffsetMs);

        // Format the machine date as a string in the machine timezone
        return machineDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            // second: 'numeric',
            //timeZone: 'UTC',
            hour12: false
        })
    }

    return (
        <>
            <div id="error-message" className={`fixed top-0 left-0 right-0 bg-yellow-100 border-t-4 border-yellow-500 p-4 z-50 ${errorMessage ? "" : "hidden"}`}>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-yellow-700 font-bold">Notice !</p>
                        <p className="text-yellow-600">{errorMessage}</p>
                    </div>
                    <button id="close-error" className="text-red-600 hover:text-yellow-800" onClick={e => { setErrorMessage(""); }}>
                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.293 5.293a1 1 0 011.414 1.414L11.414 11l4.293 4.293a1 1 0 11-1.414 1.414L10 12.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 11 4.293 6.707a1 1 0 111.414-1.414L10 9.586l4.293-4.293z" /></svg>
                    </button>
                </div>
            </div>
            <div className="min-h-full">
                <TopNavbar />
                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 flex items-center">
                        <a href={`/?platform=quickbooks_desktop`} >
                            <p className="text-xl font-thin tracking-tight text-gray-900 ml-4">Dashboard</p>
                        </a>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <a href="/mapping?platform=quickbooks_desktop" className="text-xl font-thin tracking-tight text-gray-900 ml-4">Mapping</a>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 ml-4">Run[QuickBooks Desktop]</h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <a href="/logs?platform=quickbooks_desktop" className="text-xl font-thin tracking-tight text-gray-900 ml-4">Logs</a>
                    </div>
                </header>
                <main className="container mx-auto px-4 w-full items-center justify-center">
                    <div className="min-h-screen">
                        <div className="max-w-4xl mx-auto py-16">
                            <div className="space-y-6 w-full">
                                <div className="bg-white shadow-lg rounded-lg px-6 py-4 flex items-center justify-between">
                                    <div className="w-full">
                                        <span className="flex flex-row items-center">
                                            <h2 className="font-bold text-gray-900">Synchronization</h2>
                                        </span>
                                        <div className="justify-between items-center flex mt-2">
                                            <div className="flex flex-col">
                                                <div className="items-center">
                                                    <input type="checkbox"
                                                        id="cbx_customer"
                                                        name="customer"
                                                        checked={selectedItems.customer}
                                                        onChange={handleChange}
                                                        className="mr-3"
                                                    />
                                                    <label htmlFor="cbx_customer" className="font-bold cursor-pointer">Customer <span className="ml-2 font-light text-xs">from <span className="m-2 font-bold text-green-400">QuickBooks Desktop</span> to <span className="m-2 font-bold text-blue-600">Fora Mobile Commerce Platform</span></span></label>

                                                    <span className="flex text-xs text-yellow-500">
                                                        <svg className="mr-2 w-4 h-4 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                        </svg>
                                                        <p>Only customers with Status = "Active" will be Synced</p>
                                                    </span>
                                                </div>
                                                <div className="items-center mt-4">
                                                    <input type="checkbox"
                                                        id="cbx_product"
                                                        name="product"
                                                        checked={selectedItems.product}
                                                        onChange={handleChange}
                                                        className="mr-3"
                                                    />
                                                    <label htmlFor="cbx_product" className="font-bold cursor-pointer">Product <span className="ml-2 font-light text-xs">from <span className="m-2 font-bold text-green-400">QuickBooks Desktop</span> to <span className="m-2 font-bold text-blue-600">Fora Mobile Commerce Platform</span></span></label>
                                                    <span className="flex text-xs text-yellow-500">
                                                        <svg className="mr-2 w-4 h-4 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                        </svg>
                                                        <p>Only items with Status = "Active" and Custom Field - Fora = "Yes" will be Synced</p>
                                                    </span>
                                                </div>
                                                <div className="items-center mt-4">
                                                    <input type="checkbox"
                                                        id="cbx_group"
                                                        name="group"
                                                        checked={selectedItems.group}
                                                        onChange={handleChange}
                                                        className="mr-3"
                                                    />
                                                    <label htmlFor="cbx_group" className="font-bold cursor-pointer">Group <span className="ml-2 font-light text-xs">from <span className="m-2 font-bold text-green-400">QuickBooks Desktop</span> to <span className="m-2 font-bold text-blue-600">Fora Mobile Commerce Platform</span></span></label>
                                                </div>
                                                {resyncOrder.length > 0 ?
                                                    <div className="mt-4 flex items-center">
                                                        <input type="checkbox"
                                                            id="cbx_order"
                                                            name="order"
                                                            checked={selectedItems.order}
                                                            onChange={handleChange}
                                                            className="mr-3"
                                                        />
                                                        <label htmlFor="cbx_order" className="font-bold cursor-pointer">Sync Order</label>
                                                    </div> :
                                                    <div className="justify-end mt-4">
                                                        <input type="checkbox"
                                                            id="cbx_order"
                                                            name="order"
                                                            checked={selectedItems.order}
                                                            onChange={handleChange}
                                                            className="mr-3"
                                                        />
                                                        <label htmlFor="cbx_order" className="font-bold cursor-pointer">New Order
                                                            <span className="ml-2 font-light text-xs">from <span className="m-2 font-bold text-blue-600">Fora Mobile Commerce Platform</span> to <span className="m-2 font-bold text-green-400">QuickBooks Desktop</span></span>
                                                        </label>

                                                    </div>}

                                                <div className="justify-end ml-6">
                                                    <button className="text-sm font-medium cursor-pointer underline decoration-dotted text-blue-400" onClick={_ => setOrderSelector(!orderSelector)}>or select Fora Mobile Commerce Platform Orders to sync</button>
                                                </div>
                                                <div className="grid grid-cols-3 gap-2 mt-2">
                                                    {resyncOrder.map((c, i) => {
                                                        return <div key={`resync-${i}`} className="flex items-center">
                                                            <p className="ml-2 font-bold text-xs text-blue-600">{locale(c.Key) + "#" + c.SaleOrderNumber}</p>
                                                            <button onClick={e => { removeObject(c.SaleOrderNumber); }} className={`text-xs ml-2 bg-gray-50 hover:bg-gray-100 text-red-500 border-2 border-red-500 p-1 rounded focus:outline-none focus:shadow-outline`}>Remove</button>
                                                        </div>
                                                    })}
                                                </div>

                                            </div>

                                            <div className="">
                                                <button onClick={e => { if (!loading && foraWorks && QBDCompany) runJob() }} className={`w-full flex items-center justify-center ${!loading && foraWorks && QBDCompany ? "bg-gray-50 hover:bg-gray-100 border-2 border-gray-500 text-gray-500" : "bg-gray-300 disable"} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}>
                                                    {loading ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 animated-spin">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                        : "Sync"}
                                                </button>
                                                <button className="font-bold mt-2 text-xs flex" onClick={_ => { checkForaWorks() }}>
                                                    {foraWorks ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-green-500 mr-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>

                                                        : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500 mr-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                        </svg>
                                                    }
                                                    Fora {foraCompany && foraCompany.name ?  '"' + foraCompany.name + '"' : account && account.user ? '"' + account.user.Name + '"' : null}

                                                </button>

                                                <button className="font-bold mt-1 text-xs flex" onClick={_ => { connectQuickBooksDesktop() }}>

                                                    {QBDCompany ? <><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-green-500 mr-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>QuickBooks "{QBDCompany.companyName}"</> : <>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500 mr-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                        </svg> QuickBooks</>
                                                    }

                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {orderSelector ?
                                    <div className="bg-blue-100 shadow-lg rounded-lg px-6 py-4 items-center justify-between">
                                        <div className="w-full flex items-center">
                                            <h2 className="font-bold text-gray-900 p-2">Select</h2>
                                            {/* */}
                                            <select value={selectedOrderType} onChange={e => { setSelectedOrderType(e.target.value); setOrder([]) }} className="appearance-none bg-white border-2 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline" >
                                                <option value="0">Order</option>
                                                <option value="1">Quote</option>
                                            </select>
                                            <h2 className="font-bold text-gray-900 p-2">At</h2>
                                            <DatePicker
                                                className="w-full appearance-none bg-white border-2 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline"
                                                selected={selectedDate}
                                                onChange={date => setSelectedDate(date)}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Select a date"
                                            />
                                            <button onClick={e => { setLoading(true); timerId = setTimeout(fetchOrder, 1); }} className={`ml-4 flex items-center justify-center ${!loading && foraWorks ? "bg-gray-50 hover:bg-gray-100 border-2 border-gray-500 text-gray-500" : "bg-gray-300 disable"} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}>
                                                {loading ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 animated-spin">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                    : "Show"}
                                            </button>
                                            {orderMessage ? <p className="ml-4 text-xs font-medium">{orderMessage}</p> : null}
                                        </div>
                                        {order && order.length > 0 ?
                                            <div className="mt-4 overflow-x-auto">
                                                <table className="divide-y divide-gray-200 table-fixed w-full">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="w-2/6 px-6 py-3 text-center text-xs font-medium text-gray-500">

                                                            </th>
                                                            <th scope="col" className="w-3/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                                Detail
                                                            </th>
                                                            <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                                At
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">

                                                        {order && order.map((k, i) => (
                                                            <tr key={k.saleOrderNumber}>

                                                                <td className="p-3 flex justify-start items-center">
                                                                    <input type="checkbox"
                                                                        id={`cbx_order-${k.saleOrderNumber}`}
                                                                        checked={resyncOrder.some(obj => obj.SaleOrderNumber === k.saleOrderNumber)}
                                                                        onChange={e => {
                                                                            if (e.target.checked) {
                                                                                selectOrder(k.saleOrderNumber, selectedOrderType == "0" ? "orders" : "quotes")
                                                                            } else {
                                                                                removeObject(k.saleOrderNumber)
                                                                            }
                                                                        }}
                                                                    ></input>
                                                                    <label htmlFor={`cbx_order-${k.saleOrderNumber}`} className="flex justify-start items-center cursor-pointer">

                                                                        <p className="ml-4 items-center font-medium">{i + 1}.</p>
                                                                        <div className="ml-2 text-sm text-gray-900 text-left">
                                                                            {(selectedOrderType == "0" ? "Order" : "Quote")}#{k.saleOrderNumber}

                                                                        </div>
                                                                    </label>

                                                                </td>
                                                                <td className="p-3 whitespace-nowrap">

                                                                    <p className="ml-2 text-gray-900 truncate whitespace-pre-line text-xs underline decoration-dotted ">{(k.promotionTitle)}</p>
                                                                    <p className="ml-2 text-gray-900 truncate whitespace-pre-line text-sm">{(k.customer.customerName)}</p>
                                                                </td>
                                                                <td className="p-2 whitespace-pre-line">
                                                                    <div className="text-xs text-gray-900">{formatDate(k.createDate)}</div>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div> : null}
                                    </div>

                                    : null}
                                <div className="bg-white shadow-lg rounded-lg px-6 py-4 items-center justify-between">
                                    <div className="w-full flex">
                                        <h2 className="font-bold text-gray-900 p-2">Latest Jobs</h2>

                                        <button onClick={e => { setLoadingLog(true); timerId = setTimeout(logs, 1000); }} className={`font-bold p-2`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`w-4 h-4 ${loadingLog ? "animated-spin" : ""}`}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="mt-4 overflow-x-auto">
                                        <table className="divide-y divide-gray-200 table-fixed w-full">
                                            <thead className="bg-gray-50">
                                                <tr>

                                                    <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                        Job
                                                    </th>
                                                    <th scope="col" className="w-4/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                        Result
                                                    </th>
                                                    <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                        Succeeded At
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">

                                                {data && data.map((k) => (
                                                    <tr key={k.id}>
                                                        {/* <td className="px-6 py-4">
                                                        <div className="text-xs text-gray-900">
                                                            {locale(JSON.parse(k.data).platform)}
                                                        </div>
                                                    </td> */}
                                                        <td className="p-3">
                                                            <div className="text-sm text-gray-900">
                                                                {locale(JSON.parse(k.data).job)}
                                                                {
                                                                    canResync(k.data) && JSON.parse(k.data).job == "order" ?
                                                                        <button onClick={e => { resync(k.data) }} className={`text-xs ml-2 bg-gray-50 hover:bg-gray-100 border-2 border-gray-500 text-gray-500 p-1 rounded focus:outline-none focus:shadow-outline font-bold`}>Re-sync</button>
                                                                        : null
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="p-3 whitespace-nowrap">
                                                            <span className={`items-start text-xs ${parseStatus(k.data) == "OK" ? "text-green-600" : "text-red-600"} flex`}>{parseStatus(k.data)}<p className="ml-2 text-gray-900 truncate whitespace-pre-line">{parseResult(k.data)}</p></span>
                                                        </td>
                                                        <td className="p-2 whitespace-pre-line">
                                                            <div className="text-xs text-gray-900">{formatDate(k.createdAt)}</div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div >
        </>
    )
}