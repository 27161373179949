import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as auth from '../../services/auth'
import * as api from '../../services/api'
import * as qbd from '../../services/qbd'
import * as Account from '../../account';
import '../../index.css';
import Xarrow from "react-xarrows";
import flat from 'flat'
import { sortOnKeys } from "../../utils";
import moment from 'moment';
export default function Mapping(props) {

    var account = Account.getUserAccount()
    const [loading, setLoading] = React.useState(false)
    const [searching, setSearching] = React.useState(false)
    const [filterValues, setFilterValues] = React.useState("")
    const [filterValues2, setFilterValues2] = React.useState("")
    const [filterValues3, setFilterValues3] = React.useState("")
    const [filterData, setFilterData] = React.useState("")
    const [errorMessage, setErrorMessage] = useState("");

    const [keyValues, setKeyValues] = React.useState({})
    const [keyValues2, setKeyValues2] = React.useState({})

    const [value, setValue] = React.useState("")

    const [sourceModel, setSourceModel] = React.useState({})
    const [destinationModel, setDestinationModel] = React.useState({})
    const [customConfig, setCustomConfig] = React.useState([])
    const [sampleData, setSampleData] = React.useState({})
    const [sampleForaOrder, setSampleForaOrder] = React.useState({})

    const [QBDCompany, setQBDCompany] = useState(null);
    const [QBOCompany, setQBOCompany] = useState(null);
    const [foraWorks, setForaWorks] = useState(false);

    const [source, setSource] = React.useState("")
    const [destination, setDestination] = React.useState("")

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const platform = params.get('platform');
    const model = params.get('model');
    const df = params.get('df');

    const [selectedModel, setSelectedModel] = React.useState(model ? model : "customer")
    const [selectedPlatform, setSelectedPlatform] = React.useState(platform ? platform : "quickbooks_online")
    const [foraCompany, setForaCompany] = useState(null);

    const onlineQBOrder = [
        "BillAddr.Id",
        "BillAddr.Line3",
        "BillAddr.Line4",
        "BillAddr.Line5",
        "BillAddr.Lat",
        "BillAddr.Long",
        "BillAddr.Tag",
        "BillAddr.Note",
        "BillAddr.County",
        "BillAddr.PostalCodeSuffix",
        "BillAddr.CountrySubDivisionCode",
        "BillAddr.CountryCode",

        "ShipAddr.Id",
        "ShipAddr.Line3",
        "ShipAddr.Line4",
        "ShipAddr.Line5",
        "ShipAddr.Lat",
        "ShipAddr.Long",
        "ShipAddr.Tag",
        "ShipAddr.Note",
        "ShipAddr.County",
        "ShipAddr.PostalCodeSuffix",
        "ShipAddr.CountrySubDivisionCode",
        "ShipAddr.CountryCode"

    ]

    const mappingForaOrder = [
        "SaleOrderNumber",
        "RefNumber",
        "OrderID",
        "ShipToPhone",
        "ProductList",
        "IsExport",
        "ExportDate",
        "ShipComplete",
        "ShipTrack",
        "Shipped",
        "ShipToType",
        "IsExpired",
        "IsCancel",
        "CancelReason",
        "Customer",
        "OrganizationID",
        //"CreateDate",
        "City",
        "Country",
        "State",
        "Zip",
        "Address1",
        "Address2",
        "Quantity",
        //"PromotionID",
        //"Fullname",
        // "ShipToPhone",
        "ShipToFullname",
        "InvoiceNumber",
        "BillDate",
        "RequestShipDate",
        "OrderTitle",
        "FOB",
        "CurrencyCode",
        "CurrencySymbol",
        "WeightUnit",
        "VolumeUnit",
        "ItemTotalItems",
        "ItemTotalLines",
        "ItemTotalSurcharge",
        "ItemTotalDiscount",
        "ItemTotalTax",
        "FreightPercent",
        "FreightAmount",
        "SurchargePercent",
        "SurchargeAmount",
        // "DiscountPercent",
        // "DiscountAmount",
        "DiscountMessage",
        "TaxPercent",
        //"OrderGrandTotal",
        "ItemSubtotal",
        "OrderSubtotal",
        "PaymentReferenceNo",
        "PaymentInfo",
        "CartType",
        "ReversalAmount",
        "IsReversal",
        "IsRefund",
        "ReversalDate",
        "CreditCardID",
        "IsCreditCard",
        "CurrencyAmount",
        "PriceFormat",
        "FormatAmount",
        "FormatPaid",
        "IsDelivery",
        "EventID",
        "UsePoobjects",
        "PoobjectsPayAmount",
        "EmpStatus",
        "RequestTime",
        "District",
        "Status",
        "SendType",
        "TransactionDate",
        "EmpProbjectStatus",
        "AddressID",
        "ItemTotalExtcharge",
        "ShipToAddressID",
        "NormalTime",
        "ReceivingTime",
        "PayStatus",
        "TimeDes",
        "EmployeeID",
        "CreditCradNumber",
        "StatusStr",
        "CustmoerEmail",
        "SellingUnit",
        "Catalog",
        //"PromotionTitle",
        "PromotionStart",
        "PromotionEnd",
        "Amount",
        "CatalogName",
        "CardInfoType",
        "OrderPaymentMethodDisplay",
        "AccountNo",
        "OrgIndustry",
        "OrgDeliveryStatus",
        "CartID",
        "VendorName",
        "ShowroomName",
        "Promotion",
        "CouponDiscountPercent",
        "CouponDiscountAmount",
        "CouponDiscountFreeFreight",
        "CouponDiscountMessage",
        "OrderAmount",
        "CardNumber",
        "ItemNo",
        "UPC",
        "Description",
        "ModifierAndRemark",
        "Color",
        "Size",
        "Unit",
        "Price",
        "CasePackQty",
        "TaxRate",
        "UserDefinesJsonResult",
        "UserDefinedJsonList",
        "UserDefined1Value",
        "UserDefined2Value",
        "UserDefined3Value",
        "Product.InterfaceProductID",
        "Product.ProductOrderID",
        "Product.CartID",
        "Product.PromotionID",
        "Product.OrganizationID",
        "Product.OtherInfo",
        "Product.DescriptionInfo",
        "Product.SaleTaxPercent",
        "Product.DiscountPercent",
        "Product.OnHandQuantity",
        "Product.Taxable",
        "Product.DiscountedItem",
        "Product.Size",
        "Product.Color",
        "Product.Hashtags",
        "Product.Weights",
        "Product.Volumes",
        "Product.CasePacking",
        "Product.Dimension",
        "Product.Brand",
        "Product.Prices",
        "Product.UserDefines",
        "Product.UserDefinedJsonList",
        "Product.UserDefinedInfo",
        "Product.Units",
        "Product.CasePrices",
        "Product.AdditionalInfosJsonList",
        "Product.AdditionalInfo",
        "Product.LatestUpdate",
        "Product.DefaultSellingUnit",
        "Product.IsOrdered",
        "Product.ReferenceToPromotionID",
        "Product.ReferenceToProductID",
        "Product.Added",
        "Product.CategoryID",
        "Product.SelectedPrice",
        "Product.CurrencySymbol",
        "Product.ItemUnitFlag",
        "Product.ItemWeight",
        "Product.WeightUnit",
        "Product.ItemVolume",
        "Product.VolumeUnit",
        "Product.ItemSurcharge",
        //"Product.ItemDiscount",
        "Product.Quantity",
        "Product.ItemTax",
        "Product.SelectedCurrencyCode",
        "Product.SelectedCurrencySymbol",
        "Product.UserItemRemark",
        "Product.SortBy",
        "Product.Name",
        "Product.StyleNo",
        "Product.Description2",
        "Product.SingleQuantity",
        "Product.ImageUpobject",
        "Product.ShippingSubCharge",
        "Product.FromOrganizationID",
        "Product.ExtCharge",
        "Product.MenuStatus",
        "Product.RemoveFavorites",
        "Product.DerateQuantity",
        "Product.OrderRemark",
        "Product.VideoUrl",
        "Product.ImageUrl",
        "Product.IsBulkSelling",
        "Product.PriceFormat",
        "Product.QuantityFormat",
        "Product.WaitID",
        "Product.Comped",
        "Product.QtyShipped",
        "Product.ShipQty",
        "Product.IsShip",
        "Product._UserDefine",
        "Customer.CustomerID",
        "Customer.InterfaceCustomerID",


    ]

    const desktopQBorder = [
        "SaleOrderNumber",
        "RefNumber",
        //"DueDate",
        "CustomerRef",
        "ClassRef",
        "TemplateRef",
        "IsActive",
        "TermsRef",
        "SalesRepRef",
        "OrderBy",
        "FOB",
        "ShipMethodRef",
        "ItemSalesTaxRef",
        "IsManuallyClosed",
        //"Memo",
        "CustomerMsgRef",
        "IsToBePrinted",
        "IsToBeEmailed",
        "IsTaxIncluded",
        "CustomerSalesTaxCodeRef",
        // "Other",
        "ExchangeRate",
        "ExternalGUID",
        "SOChannel",
        "StoreName",
        "StoreType",
        "DefMacro",
        "Text",

        "Product.ItemRef.ListID",
        //"Product.UnitOfMeasure",
        "Product.PriceLevelRef",
        "Product.ClassRef",
        "Product.OptionForPriceRuleConflict",
        "Product.InventorySiteRef",
        "Product.InventorySiteLocationRef",
        "Product.SalesTaxCodeRef",
        "Product.OverrideItemAccountRef",
        "Product.IsManuallyClosed",
        // "Product.Other1",
        // "Product.Other2",
        "Product.DataExt",
        //"Product.Rate",
        "Product.RatePercent",
        "Product.LotNumber",
        "Product.MarkupRate",
        "Product.MarkupRatePercent",
        "Product.SerialNumber",

        "BillAddress.Addr3",
        "BillAddress.Addr4",
        "BillAddress.Addr5",
        // "BillAddress.Country",
        "BillAddress.Note",
        "BillAddress.DefaultShipTo",
        "ShipAddress.Addr3",
        "ShipAddress.Addr4",
        "ShipAddress.Addr5",
        //"ShipAddress.Country",
        "ShipAddress.Note",
        "ShipAddress.DefaultShipTo",


        //"Customer.Name",
        "Customer.IsActive",
        "Customer.ClassRef",
        "Customer.ParentRef",
        "Customer.CompanyName",
        "Customer.Salutation",
        "Customer.FirstName",
        "Customer.MiddleName",
        "Customer.LastName",
        "Customer.JobTitle",
        "Customer.BillAddress",
        "Customer.ShipAddress",
        "Customer.ShipToAddress",
        "Customer.Phone",
        "Customer.AltPhone",
        "Customer.Fax",
        "Customer.Email",
        "Customer.Cc",
        "Customer.Contact",
        "Customer.AltContact",
        "Customer.AdditionalContactRef",
        "Customer.Contacts",
        "Customer.CustomerTypeRef",
        "Customer.TermsRef",
        "Customer.SalesRepRef",
        "Customer.OpenBalance",
        "Customer.OpenBalanceDate",
        "Customer.SalesTaxCodeRef",
        "Customer.ItemSalesTaxRef",
        "Customer.SalesTaxCountry",
        "Customer.ResaleNumber",
        //"Customer.AccountNumber",
        "Customer.CreditLimit",
        "Customer.PreferredPaymentMethodRef",
        "Customer.CreditCardInfo",
        "Customer.JobStatus",
        "Customer.JobStartDate",
        "Customer.JobProjectedEndDate",
        "Customer.JobEndDate",
        "Customer.JobDesc",
        "Customer.JobTypeRef",
        "Customer.Notes",
        "Customer.AdditionalNotes",
        "Customer.PreferredDeliveryMethod",
        "Customer.PriceLevelRef",
        "Customer.ExternalGUID",
        "Customer.TaxRegistrationNumber",
        "Customer.CurrencyRef"
    ]

    const notInterested = {
        "quickbooks_online_product": [
            // "user_defined1_name",
            // "user_defined1_value",
            // "user_defined2_name",
            // "user_defined2_value",
            // "user_defined3_name",
            // "user_defined3_value",
            "image_1",
            "image_2",
            "image_3",
            "image_4",
            "image_5",
            "image",
            "shipping_surcharge",
            "searchable_tags",

            // // "Name",
            // // "Sku",
            // // "Description",
            "Active",
            "ActiveSpecified",
            "SubItem",
            "SubItemSpecified",
            "ParentRef",
            "Level",
            "LevelSpecified",
            // // "FullyQualifiedName",
            "Taxable",
            "TaxableSpecified",
            "SalesTaxIncluded",
            "SalesTaxIncludedSpecified",
            // "PercentBased",
            "PercentBasedSpecified",
            // "UnitPrice",
            "UnitPriceSpecified",
            // "RatePercent",
            "RatePercentSpecified",
            "Type",
            "TypeSpecified",
            "PaymentMethodRef",
            "UOMSetRef",
            "IncomeAccountRef",
            // "PurchaseDesc",
            "PurchaseTaxIncluded",
            "PurchaseTaxIncludedSpecified",
            // "PurchaseCost",
            "PurchaseCostSpecified",
            "ExpenseAccountRef",
            "COGSAccountRef",
            "AssetAccountRef",
            "PrefVendorRef",
            // "AvgCost",
            "AvgCostSpecified",
            // "TrackQtyOnHand",
            "TrackQtyOnHandSpecified",
            // "QtyOnHand",
            "QtyOnHandSpecified",
            // "QtyOnPurchaseOrder",
            "QtyOnPurchaseOrderSpecified",
            // "QtyOnSalesOrder",
            "QtyOnSalesOrderSpecified",
            "ReorderPoint",
            "ReorderPointSpecified",
            // "ManPartNum",
            "DepositToAccountRef",
            "SalesTaxCodeRef",
            "PurchaseTaxCodeRef",
            "InvStartDate",
            "InvStartDateSpecified",
            "BuildPoint",
            "BuildPointSpecified",
            "PrintGroupedItems",
            "PrintGroupedItemsSpecified",
            "SpecialItem",
            "SpecialItemSpecified",
            "SpecialItemType",
            "SpecialItemTypeSpecified",
            "ItemGroupDetail",
            "ItemAssemblyDetail",
            "AbatementRate",
            "AbatementRateSpecified",
            "ReverseChargeRate",
            "ReverseChargeRateSpecified",
            "ServiceType",
            "ItemCategoryType",
            "ItemEx",
            "TaxClassificationRef",
            "UQCDisplayText",
            "UQCId",
            "ClassRef",
            "Source",
            "DeferredRevenue",
            "DeferredRevenueSpecified",
            "Id",
            "SyncToken",
            "MetaData",
            "CustomField",
            "AttachableRef",
            "domain",
            "status",
            "statusSpecified",
            "sparse",
            "sparseSpecified",
            "NameAndId",
            "Overview",
            "HeaderLite",
            "HeaderFull"
        ],
        "quickbooks_online_customer": [
            "Suffix",
            "Title",
            "Taxable",
            "TaxableSpecified",
            "BillAddr.Id",

            "BillAddr.Line3",
            "BillAddr.Line4",
            "BillAddr.Line5",
            // "BillAddr.CountryCode",
            "BillAddr.County",
            // "BillAddr.CountrySubDivisionCode",
            "BillAddr.PostalCodeSuffix",
            "BillAddr.Lat",
            "BillAddr.Long",
            "BillAddr.Tag",
            "BillAddr.Note",
            "ShipAddr.Id",
            "ShipAddr.Line3",
            "ShipAddr.Line4",
            "ShipAddr.Line5",
            // "ShipAddr.CountryCode",
            "ShipAddr.County",
            // "ShipAddr.CountrySubDivisionCode",
            "ShipAddr.PostalCodeSuffix",
            "ShipAddr.Lat",
            "ShipAddr.Long",
            "ShipAddr.Tag",
            "ShipAddr.Note",
            "OtherAddr",
            "AltContactName",
            "Notes",
            "Job",
            "JobSpecified",
            "BillWithParent",
            "BillWithParentSpecified",
            "RootCustomerRef",
            "ParentRef",
            // "Level",
            "LevelSpecified",
            // "CustomerTypeRef",
            // "SalesTermRef",
            // "SalesRepRef",
            "AnyIntuitObject",
            "ItemElementName",
            // "PaymentMethodRef",
            // "CCDetail",
            // "PriceLevelRef",
            "Balance",
            "BalanceSpecified",
            "OpenBalanceDate",
            "OpenBalanceDateSpecified",
            "BalanceWithJobs",
            "BalanceWithJobsSpecified",
            // "CreditLimit",
            // "CreditLimitSpecified",
            // "CurrencyRef.name",
            // "CurrencyRef.type",
            "OverDueBalance",
            "OverDueBalanceSpecified",
            "TotalRevenue",
            "TotalRevenueSpecified",
            "TotalExpense",
            "TotalExpenseSpecified",
            // "PreferredDeliveryMethod",
            // "ResaleNum",
            // "JobInfo",
            "TDSEnabled",
            "TDSEnabledSpecified",
            "CustomerEx",
            "SecondaryTaxIdentifier",
            "ARAccountRef",
            "PrimaryTaxIdentifier",
            "TaxExemptionReasonId",
            "IsProject",
            "IsProjectSpecified",
            // "BusinessNumber",
            "GSTIN",
            "GSTRegistrationType",
            "IsCISContractor",
            "IsCISContractorSpecified",
            "ClientCompanyId",
            "ClientEntityId",
            // "Source",
            "TaxRegime",
            "IntuitId",
            // "Organization",
            "OrganizationSpecified",
            // "Title",
            // "Suffix",
            "FullyQualifiedName",
            "PrintOnCheckName",
            // "UserId",
            "Active",
            "ActiveSpecified",
            "V4IDPseudonym",
            "PrimaryPhone.Id",
            "PrimaryPhone.DeviceType",
            "PrimaryPhone.CountryCode",
            "PrimaryPhone.AreaCode",
            "PrimaryPhone.ExchangeCode",
            "PrimaryPhone.Extension",
            "PrimaryPhone.Default",
            "PrimaryPhone.DefaultSpecified",
            "PrimaryPhone.Tag",
            "AlternatePhone",
            // "Mobile",
            "PrimaryEmailAddr.Id",
            "PrimaryEmailAddr.Default",
            "PrimaryEmailAddr.DefaultSpecified",
            "PrimaryEmailAddr.Tag",
            // "WebAddr",
            // "OtherContactInfo",
            // "DefaultTaxCodeRef",
            "Id",
            "SyncToken",
            "MetaData.CreatedByRef",
            "MetaData.CreateTime",
            "MetaData.CreateTimeSpecified",
            "MetaData.LastModifiedByRef",
            "MetaData.LastUpdatedTime",
            "MetaData.LastUpdatedTimeSpecified",
            "MetaData.LastChangedInQB",
            "MetaData.LastChangedInQBSpecified",
            "MetaData.Synchronized",
            "MetaData.SynchronizedSpecified",
            "MetaData",
            // "CustomField",
            // "AttachableRef",
            "domain",
            "status",
            "statusSpecified",
            "sparse",
            "sparseSpecified",
            // "NameAndId",
            "HeaderLite",
            "HeaderFull",
            "CustomerTypeRef",
            "CreditLimitSpecified",
            "CurrencyRef",
            "SalesTermRef",
            "SalesRepRef",
            "PaymentMethodRef",
            "PriceLevelRef",
            "DefaultTaxCodeRef",
            "AttachableRef",
            "WebAddr.Id",
            "WebAddr.Default",
            "WebAddr.DefaultSpecified",
            "WebAddr.Tag",
            "Fax.Id",
            "Fax.DeviceType",
            "Fax.CountryCode",
            "Fax.AreaCode",
            "Fax.ExchangeCode",
            "Fax.Extension",
            "Fax.Default",
            "Fax.DefaultSpecified",
            "Fax.Tag",
            "UserId",
            "Mobile.Id",
            "Mobile.DeviceType",
            "Mobile.CountryCode",
            "Mobile.AreaCode",
            "Mobile.ExchangeCode",
            "Mobile.Extension",
            "Mobile.Default",
            "Mobile.DefaultSpecified",
            "Mobile.Tag",
            "CCDetail",
            "Level",
            "JobInfo",
            "Organization",
            "Source",
            "CustomField",
            "NameAndId",
            "Overview",
            "ContactName",
            "OtherContactInfo"

            // "Click_through_web_site_login_parameter",
        ],
        "quickbooks_desktop_customer": [
            "Salutation",
            "Name",
            "ClassRef",
            "ParentRef",
            "BillAddressBlock",
            "ShipAddressBlock",
            "ShipToAddress",
            "AdditionalContactRef",
            "Contact",
            "ContactsRet",
            "ResaleNumber",
            "SalesTaxCountry",
            "ListID",
            "AltContact",
            "PreferredDeliveryMethod",
            "TaxRegistrationNumber",
            "CustomerTypeRef.ListID",
            "TermsRef.ListID",
            "SalesRepRef.ListID",
            "BillAddress.Note",
            "BillAddress.DefaultShipTo",
            "ShipAddress.Addr1",
            "ShipAddress.Addr2",
            "ShipAddress.Addr3",
            "ShipAddress.Addr4",
            "ShipAddress.Addr5",
            "ShipAddress.City",
            "ShipAddress.State",
            "ShipAddress.PostalCode",
            "ShipAddress.Country",
            "ShipAddress.Note",
            "ShipAddress.DefaultShipTo",
            "ship_address.Note",
            "ship_address.DefaultShipTo",
            "JobTitle",
            "EditSequence",
            "Sublevel",
            "TotalBalance",
            "Balance",
            "TimeCreated",
            "TimeModified",
            "IsActive",
            "SalesTaxCodeRef",
            "ItemSalesTaxRef",
            "PreferredPaymentMethodRef",
            "AltPhone",
            "JobStatus",
            "JobStartDate",
            "JobProjectedEndDate",
            "JobEndDate",
            "JobDesc",
            "JobTypeRef",
            "AdditionalNotesRet",
            "PriceLevelRef.ListID",
            "CurrencyRef",
            "DataExtRet",
            "ExternalGUID"
        ],
        "quickbooks_desktop_product": [
            "image_1",
            "image_2",
            "image_3",
            "image_4",
            "image_5",
            "image",
            "shipping_surcharge",
            "searchable_tags",

            //"Name",
            "ListID",
            "TimeCreated",
            "TimeModified",
            "EditSequence",
            // "BarCodeValue",
            "IsActive",
            "ClassRef.ListID",
            "ParentRef.ListID",
            "Sublevel",
            // "ManufacturerPartNumber",
            "ClassRef.ListID",
            "UnitOfMeasureSetRef.ListID",
            "IsTaxIncluded",
            "SalesTaxCodeRef.ListID",
            "IncomeAccountRef.ListID",
            "PurchaseTaxCodeRef.ListID",
            "COGSAccountRef.ListID",
            "PrefVendorRef.ListID",
            "AssetAccountRef.ListID",
            "ReorderPoint",
            "Max",
            "ExternalGUID",
            "DataExtRet",
            "UnitOfMeasureSet",
        ],
        "quickbooks_desktop_order_fora": [...mappingForaOrder],
        "quickbooks_desktop_order_qb": [...desktopQBorder],
        "quickbooks_online_order_fora": [...mappingForaOrder],
        "quickbooks_online_order_qb": [...onlineQBOrder],


    }

    useEffect(() => {
        setLoading(true)
        setFilterData("")
        setFilterValues("")
        setFilterValues2("")
        setFilterValues3("")
        setSource("")
        setDestination("")
        setCustomConfig([])
        setSampleData({})
        setSampleForaOrder({})
        setSourceModel({})
        setDestinationModel({})
        setKeyValues({})
        setKeyValues2({})
        setValue("")


        if (df)
            api.getConfig(selectedPlatform, selectedModel)
                .then(response => {
                    setCustomConfig(response.fields)
                })
                .catch(err => {
                    if (err && err.data && err.data.error)
                        setErrorMessage(err.data.error)
                    console.log(err)
                }).finally(_ => {

                })
        else
            api.getCustomConfig(selectedPlatform, selectedModel)
                .then(response => {
                    setCustomConfig(response.fields)
                })
                .catch(err => {
                    if (err && err.data && err.data.error)
                        setErrorMessage(err.data.error)
                    console.log(err)
                }).finally(_ => {

                })


        if (!window.localStorage.getItem("qbd") && selectedPlatform == "quickbooks_desktop") {
            connectQuickBooksDesktop()
        }

        if (selectedPlatform == "quickbooks_online") {
            checkQBWorks()
        }

        checkForaWorks()

    }, [selectedModel, selectedPlatform])


    function handleOptionChange(e) {
        var { name, value } = e.target
        setSelectedPlatform(value)

    }

    const checkForaWorks = () => {
        setLoading(true)
        api.getHealth()
            .then(response => {
                setForaWorks(response.success)
                if (response.model)
                    setForaCompany(response.model)
            })
            .catch(err => {
                setForaWorks(false)
            }).finally(_ => {
                setLoading(false)
            })
    }

    const connectQuickBooksDesktop = () => {
        setQBDCompany(null)
        qbd.connect().then(response => {
            setQBDCompany(response)
        }).catch(err => {
            if (err && err.data && err.data.error)
                setErrorMessage(err.data.error)
            console.log(err)
        }).finally(_ => {
            getModel({})
        })
    };

    const checkQBWorks = () => {
        api.quickBooksCompanyInfo()
            .then(response => {
                setQBOCompany(response)
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                getModel({})
            })
    }




    const getModel = (sm) => {
        api.getModel(selectedPlatform, selectedModel)
            .then(model => {
                //console.log(Object.keys(model.sub.Product))
                if (selectedModel.startsWith('order'))
                    setDestinationModel({ ...model.main, ...model.sub, ...sm })
                else
                    setSourceModel({ ...model.main, ...model.sub, ...sm })

            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                setLoading(false)
            })

        api.getModel("fora", selectedModel)
            .then(response => {
                // console.log(Object.keys(response.sub.Product))
                if (selectedModel.startsWith('order'))
                    setSourceModel({ ...response.main, ...response.sub })
                else
                    setDestinationModel({ ...response.main, ...response.sub })
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            }).finally(_ => {
                setLoading(false)
            })

    }
    const searchSample = () => {
        setErrorMessage("")
        if (df) {
            getModel({})
            return
        }

        //disconnected state
        if (window.localStorage.getItem("qbd") && selectedPlatform == "quickbooks_desktop") {
            getModel({})
            return;
        }

        var sm = {}
        //load sample data
        if (selectedModel == "customer") {
            if (selectedPlatform == "quickbooks_online") {
                setSearching(true)
                api.quickBooksCustomers(filterData)
                    .then(sample => {

                        if (sample && sample.DataExtRetList) {
                            sample.DataExtRetList.forEach(c => {
                                sm["custom_field:" + c.DataExtName] = null
                                sm["custom_field:" + c.DataExtName + "_value"] = null
                                sample["custom_field:" + c.DataExtName] = c.DataExtName
                                sample["custom_field:" + c.DataExtName + "_value"] = c.DataExtValue
                            });
                        }

                        setSampleData(sample)
                    })
                    .catch(err => {
                        if (err && err.data && err.data.error)
                            setErrorMessage(err.data.error)
                        console.log(err)
                    }).finally(_ => {
                        setSearching(false)
                        getModel(sm)
                    })

            } else {
                setSearching(true)
                qbd.sampleCustomer(filterData)
                    .then(sample => {
                        if (!sample.ListID) {
                            setErrorMessage(`No customer found for search term "${filterData}"`)
                        } else {

                            if (sample && sample.AdditionalContactRef) {
                                sample.AdditionalContactRef.forEach(c => {
                                    sm["additional_contact:" + c.ContactName] = null
                                    sample["additional_contact:" + c.ContactName] = c.ContactValue
                                });
                            }

                            if (sample && sample.DataExtRetList) {
                                sample.DataExtRetList.forEach(c => {
                                    sm["custom_field:" + c.DataExtName] = null
                                    sm["custom_field:" + c.DataExtName + "_value"] = null
                                    sample["custom_field:" + c.DataExtName] = c.DataExtName
                                    sample["custom_field:" + c.DataExtName + "_value"] = c.DataExtValue
                                });
                            }

                            if (sample && sample.ShipToAddress) {
                                sample.ShipToAddress.forEach(c => {
                                    if (c.DefaultShipTo) {
                                        for (let prop in c) {
                                            sample["ship_address." + prop] = c[prop]
                                        }
                                    }
                                });
                            }

                            if (sample && sample.CreditCardInfo && sample.CreditCardInfo.CreditCardNumber)
                                sample.CreditCardInfo.CreditCardNumber = sample.CreditCardInfo.CreditCardNumber.slice(0, -4).replace(/./g, 'X') + sample.CreditCardInfo.CreditCardNumber.slice(-4);


                            setSampleData(sample)
                        }
                    }).catch(err => {
                        if (err && err.data && err.data.error)
                            setErrorMessage(err.data.error)
                        console.log(err)
                    }).finally(_ => {
                        setSearching(false)
                        getModel(sm)
                    })
            }

        }

        if (selectedModel == "product") {
            if (selectedPlatform == "quickbooks_online") {
                setSearching(true)
                api.quickBooksItems(filterData)
                    .then(sample => {
                        if (sample && sample.DataExtRetList) {
                            sample.DataExtRetList.forEach(c => {
                                sm["custom_field:" + c.DataExtName] = null
                                sm["custom_field:" + c.DataExtName + "_value"] = null
                                sample["custom_field:" + c.DataExtName] = c.DataExtName
                                sample["custom_field:" + c.DataExtName + "_value"] = c.DataExtValue
                            });

                            setSampleData(sample)
                        }
                    })
                    .catch(err => {
                        if (err && err.data && err.data.error)
                            setErrorMessage(err.data.error)
                        console.log(err)
                    }).finally(_ => {
                        setSearching(false)
                        getModel(sm)
                    })
            } else {
                setSearching(true)
                qbd.sampleProduct(filterData)
                    .then(sample => {
                        if (!sample.ListID) {
                            setErrorMessage(`No item found for search term "${filterData}"`)
                        } else {
                            if (sample && sample.UnitOfMeasureSet) {

                                sm["u/m:Name"] = null
                                sm["u/m:UnitOfMeasureType"] = null
                                sample["u/m:Name"] = sample.UnitOfMeasureSet.Name
                                sample["u/m:UnitOfMeasureType"] = sample.UnitOfMeasureSet.UnitOfMeasureType


                                sm["u/m:BaseUnit.Name"] = null
                                sm["u/m:BaseUnit.Abbreviation"] = null
                                if (sample.UnitOfMeasureSet.BaseUnit) {
                                    sample["u/m:BaseUnit.Name"] = sample.UnitOfMeasureSet.BaseUnit.Name
                                    sample["u/m:BaseUnit.Abbreviation"] = sample.UnitOfMeasureSet.BaseUnit.Abbreviation
                                }


                                sample.UnitOfMeasureSet.DefaultUnits.forEach(c => {
                                    sm[`u/m:DefaultUnits:${c.UnitUsedFor}:UnitUsedFor`] = null
                                    sm[`u/m:DefaultUnits:${c.UnitUsedFor}:Unit`] = null
                                    sample[`u/m:DefaultUnits:${c.UnitUsedFor}:UnitUsedFor`] = c.UnitUsedFor
                                    sample[`u/m:DefaultUnits:${c.UnitUsedFor}:Unit`] = c.Unit
                                })
                                sample.UnitOfMeasureSet.RelatedUnits.forEach(c => {
                                    sm[`u/m:RelatedUnits:${c.Name}:Name`] = null
                                    sm[`u/m:RelatedUnits:${c.Name}:Abbreviation`] = null
                                    sm[`u/m:RelatedUnits:${c.Name}:ConversionRatio`] = null
                                    sample[`u/m:RelatedUnits:${c.Name}:Name`] = c.Name
                                    sample[`u/m:RelatedUnits:${c.Name}:Abbreviation`] = c.Abbreviation
                                    sample[`u/m:RelatedUnits:${c.Name}:ConversionRatio`] = c.ConversionRatio
                                })

                            }

                            if (sample && sample.DataExtRetList) {
                                sample.DataExtRetList.forEach(c => {
                                    sm["custom_field:" + c.DataExtName] = null
                                    sm["custom_field:" + c.DataExtName + "_value"] = null
                                    sample["custom_field:" + c.DataExtName] = c.DataExtName
                                    sample["custom_field:" + c.DataExtName + "_value"] = c.DataExtValue
                                });
                            }

                            setSampleData(sample)
                        }

                    }).catch(err => {
                        if (err && err.data && err.data.error)
                            setErrorMessage(err.data.error)
                        console.log(err)
                    }).finally(_ => {
                        setSearching(false)
                        getModel(sm)
                    })


            }
        }

        if (selectedModel == "order") {
            if (filterData === "") {
                getModel(sm)
                return
            }

            setSearching(true)
            api.getForaOrderDetail(filterData)
                .then(sample => {
                    if (sample && sample.model && sample.model.saleOrderNumber) {
                        var sd = sample.model
                        if (sample.model.productList) {
                            var item = sample.model.productList[0]
                            if (item)
                                sd.product = item
                        }

                        setSampleForaOrder(sd)
                    } else {
                        setErrorMessage(`No order found for search term "${filterData}"`)
                    }

                }).catch(err => {
                    if (err && err.data && err.data.error)
                        setErrorMessage(err.data.error)
                    console.log(err)
                }).finally(_ => {
                    setSearching(false)
                })

            // if (selectedPlatform == "quickbooks_online")
            //     api.quickBooksOrders(filterData)
            //         .then(sample => {
            //             setSampleData(sample)
            //         })
            //         .catch(err => {
            // if (err && err.data && err.data.error)
            // setErrorMessage(err.data.error)
            //             console.log(err)
            //         }).finally(_ => {
            //             getModel(sm)
            //         })
            // else {
            //     qbd.sampleOrder(filterData)
            //         .then(sample => {
            //             setSampleData(sample)
            //         }).catch(err => {
            // if (err && err.data && err.data.error)
            // setErrorMessage(err.data.error)
            //             console.log(err)
            //         }).finally(_ => {
            //             getModel(sm)
            //         })
            // }
        }

    }


    useEffect(() => {
        let keyOrder = ['Name', 'ParentRef.FullName', 'FullName',


            "item_no",
            "Item_name",
            "item_upc",
            "description",
            "long_description",
            "category1",
            "category2",
            "category3",
            "category4",
            "category5",
            "style",
            "color",
            "size",
            "dimension",
            "unit",
            "bulk_unit",
            "default_unit",
            "min_order_qty",
            "case_pack_qty",
            "on_hand_qty",
            "taxable",
            "tax_rate",
            "price1",
            "price2",
            "qty_break2",
            "price3",
            "qty_break3",
            "price4",
            "qty_break4",
            "price5",
            "qty_break5",
            "bulk_price1",
            "bulk_price2",
            "bulk_qty_break2",
            "bulk_price3",
            "bulk_qty_break3",
            "bulk_price4",
            "bulk_qty_break4",
            "bulk_price5",
            "bulk_qty_break5",
            "weight",
            "volume",
            "weight_for_bulk",
            "volume_for_bulk",
            "user_defined1_name",
            "user_defined1_value",
            "user_defined2_name",
            "user_defined2_value",
            "user_defined3_name",
            "user_defined3_value"

        ];
        if (selectedModel == "order")
            keyOrder = [
                // "SaleOrderNumber",
                // "RefNumber",
                "Customer.DisplayName",
                "Customer.AcctNum",
                "Customer.CustomerName",
                "Customer.CustomerAcctNo",
                "Customer.Name",
                "Customer.AccountNumber",
                "Terms",
                "Terms_",
                "PONumber",
                "TxnDate",
                "OrderDate",
                // "PromotionTitle",
                // "PromotionID",
                "ShipDate",
                "DueDate",
                "CancelDate",
                "ShippingMethod_",
                "Rep_",
                "ShipVia_",
                "SalesRep",
                // "OrderID",
                "By",
                //"FOB",
                //"OrderBy",
                "UserID",
                "ShipMethod",
                "Subtotal",
                "SalesTaxTotal",
                "AppliedAmount",
                "BalanceRemaining",
                "Memo",
                "IsPaind",
                "Other",

                "Phone",
                "CustomerEmail",
                "Fullname",
                "BillAddress.Addr1",
                "BillAddress.Addr2",
                "BillAddress.City",
                "BillAddress.State",
                "BillAddress.PostalCode",
                "BillAddress.Country",

                "ShipAddress.Addr1",
                "ShipAddress.Addr2",
                "ShipAddress.City",
                "ShipAddress.State",
                "ShipAddress.PostalCode",
                "ShipAddress.Country",

                "Customer.BillToName",
                "Customer.BillToAddress1",
                "Customer.BillToAddress2",
                "Customer.BillToCity",
                "Customer.BillToState",
                "Customer.BillToZip",
                "Customer.BillToCountry",
                "Customer.ShipToName",
                "Customer.ShipToAddress1",
                "Customer.ShipToAddress2",
                "Customer.ShipToCity",
                "Customer.ShipToState",
                "Customer.ShipToZip",
                "Customer.ShipToCountry",
                "ShipToPhone",
                "CreditCardNo",
                "OrderRemark",
                "ItemTotalWeight",
                "ItemTotalVolume",
                "ShipVia",
                "ShippingSucharge",
                "SalesTax",
                "SalesTaxAmount",
                "DiscountPercent",
                "DiscountAmount",
                "OrderGrandTotal",
                "PaymentAmount",
                "PaymentCompleted",
                "PaymentMethod",
                "ItemNo_",
                "Product.LineNo",
                "Product.ItemNo",
                "Product.ItemUPC",
                "Product.Description",
                "Product.OrderQuantity",
                "Product.ItemUnit",
                "Product.ItemDiscount",
                "Product.BasePrice",
                "Product.SellPrice",
                "Product.Remark",
                "Product.ItemExtended",

            ]


        var propsSource = `${selectedPlatform}_${selectedModel}${selectedModel != "order" ? "" : "_fora"}`
        var propsDestination = `${selectedPlatform}_${selectedModel}${selectedModel != "order" ? "" : "_qb"}`
        if (Object.keys(sourceModel).length !== 0) {
            var flattened = flat.flatten(sourceModel)
            // console.log(Object.keys(flattened))
            if (notInterested.hasOwnProperty(propsSource))
                notInterested[propsSource].forEach(c => {
                    delete flattened[c];
                })

            const sortedObject = Object.fromEntries(
                Object.entries(flattened)
                    .sort(([keyA], [keyB]) => {
                        const indexA = keyOrder.indexOf(keyA);
                        const indexB = keyOrder.indexOf(keyB);

                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }

                        if (indexA !== -1) {
                            return -1;
                        }

                        if (indexB !== -1) {
                            return 1;
                        }

                        return 0;
                    })
            );

            setKeyValues(sortedObject)
        }

        if (Object.keys(destinationModel).length !== 0) {
            var flattened = flat.flatten(destinationModel)
            // console.log(Object.keys(flattened))
            if (notInterested.hasOwnProperty(propsDestination))
                notInterested[propsDestination].forEach(c => {
                    delete flattened[c];
                })

            const sortedObject = Object.fromEntries(
                Object.entries(flattened)
                    .sort(([keyA], [keyB]) => {
                        const indexA = keyOrder.indexOf(keyA);
                        const indexB = keyOrder.indexOf(keyB);

                        if (indexA !== -1 && indexB !== -1) {
                            return indexA - indexB;
                        }

                        if (indexA !== -1) {
                            return -1;
                        }

                        if (indexB !== -1) {
                            return 1;
                        }

                        return 0;
                    })
            );

            setKeyValues2(sortedObject)
        }

    }, [sourceModel, destinationModel])

    const clearConfig = () => {
        if (window.confirm(`Do you want to clear all mapping?`)) {
            setCustomConfig([])
            saveConfig()
        }
    }
    const saveConfig = () => {
        var data = {
            platform: selectedPlatform,
            entity: selectedModel,
            fields: customConfig,
        }

        if (df)
            api.saveConfig(data)
                .then(response => {
                    alert("done")
                })
                .catch(err => {
                    if (err && err.data && err.data.error)
                        setErrorMessage(err.data.error)
                    console.log(err)
                })
        else
            api.saveCustomConfig(data)
                .then(response => {
                    alert("done")
                })
                .catch(err => {
                    if (err && err.data && err.data.error)
                        setErrorMessage(err.data.error)
                    console.log(err)
                })
    }

    Array.prototype.inArray = function (comparer) {
        for (var i = 0; i < this.length; i++) {
            if (comparer(this[i])) return true;
        }
        return false;
    };

    // adds an element to the array if it does not already exist using a comparer 
    // function
    Array.prototype.pushIfNotExist = function (element, comparer) {
        if (!this.inArray(comparer)) {
            this.push(element);
        }
    };

    function highlightSelected(key, target) {
        return customConfig.findIndex(item => toUserFriendlyText(item[target]).toLowerCase().includes(toUserFriendlyText(key).toLowerCase()));
    }

    function toUserFriendlyText(input) {


        if (!input)
            return ""

        input = input.replace("CountrySubDivisionCode", "State");
        input = input.replace(".FreeFormNumber", "");
        input = input.replace("Primary", "");
        input = input.replace("EmailAddr.Address", "Email");
        input = input.replace("WebAddr.URI", "URL");

        if (input == "DisplayName")
            input = input.replace("DisplayName", `${selectedModel} Name`)

        if (input == "Name" && selectedModel == "product")
            input = input.replace("Name", `Item Name/Number`)


        if (input == "FullName")
            input = input.replace("FullName", `Name`)


        if (input == "default_unit")
            input = input.replace("default_unit", `Default Selling Unit (0=Single, 1=Bulk)`)

        if (input == "unit")
            input = input.replace("unit", `Single U/M`)

        if (input == "bulk_unit")
            input = input.replace("bulk_unit", `Bulk U/M`)

        if (input == "item_upc")
            input = input.replace("item_upc", `Item UPC`)

        if (input == "tax_rate")
            input = input.replace("tax_rate", `Tax Rate (%)`)

        if (input == "taxable")
            input = input.replace("taxable", `Taxable (Y or N / 1 or 0)`)


        if (input == "weight")
            input = input.replace("weight", `Weight (Single Unit)`)

        if (input == "volume")
            input = input.replace("volume", `Volume (Single Unit)`)

        if (input == "weight_for_bulk")
            input = input.replace("weight_for_bulk", `Weight (Bulk Unit)`)

        if (input == "volume_for_bulk")
            input = input.replace("volume_for_bulk", `Volume (Bulk Unit)`)


        if (!input || typeof input !== "string") {
            return input;
        }

        input = input.replace("Ref.FullName", "")
        input = input.replace("CreditCardInfo.", "")

        // input = input.replace(".Address", "")
        // input = input.replace(".FreeFormNumber", "")
        // input = input.replace(".Value", "")
        // input = input.replace("Addr", "Address")
        // input = input.replace("Acct", "Account")
        // input = input.replace("Num", "Number")

        let spacedAndCapitalized = input.replace(/([a-z])([A-Z])/g, "$1 $2");
        let replacedUnderscores = spacedAndCapitalized.replace(/_/g, " ");
        let userFriendlyText = replacedUnderscores.replace(/\b\w/g, char => char.toUpperCase()).replace(".", " ").replace(/:/g, " | ");


        if (selectedModel == "order") {


            userFriendlyText = userFriendlyText.replace(/Promotion Title/g, "Campaign Title")
            userFriendlyText = userFriendlyText.replace(/Promotion ID/g, "Campaign No.")
            userFriendlyText = userFriendlyText.replace(/Product/g, "Item")
            userFriendlyText = userFriendlyText.replace(/User ID/g, "User ID (Device User ID)")
            userFriendlyText = userFriendlyText.replace(/Acct No/g, "Account Number")
            userFriendlyText = userFriendlyText.replace(/Phone/g, "Contact Phone")
            userFriendlyText = userFriendlyText.replace(/PONumber/g, "PO#")
            userFriendlyText = userFriendlyText.replace(/Customer Email/g, "Contact Email")
            userFriendlyText = userFriendlyText.replace(/Fullname/g, "Contact Full Name")
            userFriendlyText = userFriendlyText.replace(/Customer Bill To/g, "Bill-to")
            userFriendlyText = userFriendlyText.replace(/Customer Ship To/g, "Ship-to")
            userFriendlyText = userFriendlyText.replace(/Credit Card No/g, "Credit Card Number")
            // userFriendlyText = userFriendlyText.replace(/Terms/g, "Payment Terms")
            userFriendlyText = userFriendlyText.replace(/Ship To Contact Phone/g, "Ship-to Phone")

            userFriendlyText = userFriendlyText.replace(/Item Total Weight/g, "Order Total Weight")
            userFriendlyText = userFriendlyText.replace(/Item Total Volume/g, "Order Total Volume")
            userFriendlyText = userFriendlyText.replace(/Shipping Sucharge/g, "Shipping Charge")

            if (userFriendlyText == "Sales Tax")
                userFriendlyText = userFriendlyText.replace(/Sales Tax/g, "Sales Tax %")

            userFriendlyText = userFriendlyText.replace(/Discount Percent/g, "Subtotal Discount %")
            userFriendlyText = userFriendlyText.replace(/Discount Amount/g, "Subtotal Discount Amount")
            userFriendlyText = userFriendlyText.replace(/Order Grand Total/g, "Order Total")


            userFriendlyText = userFriendlyText.replace(/Item Item No/g, "Item No")
            userFriendlyText = userFriendlyText.replace(/Item Item UPC/g, "Item UPC")
            userFriendlyText = userFriendlyText.replace(/Item Item Unit/g, "Item Unit of Measure")
            userFriendlyText = userFriendlyText.replace(/Item Item Extended/g, "Item Extended")
            userFriendlyText = userFriendlyText.replace(/Item Item Discount/g, "Item Discount (%)")

            userFriendlyText = userFriendlyText.replace(/Item Sell Price/g, "Item Sell at Price")


            userFriendlyText = userFriendlyText.replace(/Ship Address/g, "Ship-to")
            userFriendlyText = userFriendlyText.replace(/Bill Address/g, "Bill-to")

            userFriendlyText = userFriendlyText.replace(/Ref Number/g, "S.O. No.")

            userFriendlyText = userFriendlyText.replace(/Txn Date/g, "Transaction Date")
            userFriendlyText = userFriendlyText.replace(/Rep /g, "Sales Rep")

            userFriendlyText = userFriendlyText.replace(/Customer Customer/g, "Customer")
            userFriendlyText = userFriendlyText.replace(/Item Item/g, "Item#")


        }



        return userFriendlyText.replace(/Parent/g, "Subitem of")
    }

    const loadDefaultConfig = () => {
        api.getConfig(selectedPlatform, selectedModel)
            .then(response => {
                var config = [...customConfig]
                for (var i in response.fields) {
                    var c = response.fields[i]
                    if (c.source && c.destination)
                        config.pushIfNotExist(c, function (e) {
                            return e.source === c.source && e.destination === c.destination;
                        });
                }
                setCustomConfig(config)
            })
            .catch(err => {
                if (err && err.data && err.data.error)
                    setErrorMessage(err.data.error)
                console.log(err)
            })
    }

    const addResult = () => {
        var config = [...customConfig]
        if (source && destination) {
            config.push({
                source: source,
                destination: destination
            })
        }

        if (value && !source && destination) {
            config.push({
                destination: destination,
                value: value
            })
        }
        setCustomConfig(config)
        setSource("")
        setDestination("")
        setValue("")
    }

    const getSample = (k) => {
        let data = sampleData && flat.flatten(sampleData) && flat.flatten(sampleData)[k] ? JSON.stringify(flat.flatten(sampleData)[k]).toString().replace("null", "").replace(/"/gi, '') : ""

        if (selectedModel == "order" && data === "" && sampleForaOrder && sampleForaOrder.createDate) {
            const foraOrder = flat.flatten(sampleForaOrder)
            for (const key in foraOrder) {
                if (foraOrder.hasOwnProperty(key) && key.toLowerCase() === k.toLowerCase()) {
                    data = foraOrder[key];
                }
            }
        }

        if (!data || data === "") return ""

        if (data === "0001-01-01T00:00:00") return ""

        if (k.includes("Date") || k.includes("date")) {
            const momentObj = moment(data);
            if (momentObj.isValid())
                return momentObj.format("DD/MM/YYYY");
            else
                return data
        } else {
            return data
        }
    }


    useEffect(() => {
        var container = document.getElementById("resultContainer");
        container.scrollTop = container.scrollHeight;
    }, [customConfig])

    const tableHeader = (swap) => {
        if (swap) {
            return <tr className="w-full">
                <th className="py-1 text-xs">
                    <div className="relative m-1">

                        <select name="platform" value={selectedPlatform} onChange={handleOptionChange} className="w-[180px] appearance-none bg-white border-2 border-gray-400 hover:border-gray-500 px-2 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline" id="select-option">
                            <option value="quickbooks_online">QuickBooks Online</option>
                            <option value="quickbooks_desktop">QuickBooks Desktop</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 12l-5-5 1.41-1.41L10 9.17l3.59-3.58L15 7z" />
                            </svg>
                        </div>
                    </div>
                </th>
                <th className="px-2 py-1 w-full"><input placeholder="Field searching..." onChange={e => { if (!loading) setFilterValues(e.target.value) }} value={filterValues} className="w-full bg-white border-2 border-gray-300 rounded-md p-1 text-md font-thin" type="text"></input></th>
            </tr>
        } else {
            return <tr className="w-full">
                <th className="px-2 py-1">Fora</th>
                <th className="px-2 py-1 w-full"><input placeholder="Field searching..." onChange={e => { if (!loading) setFilterValues2(e.target.value) }} value={filterValues2} className="w-full bg-white border-2 border-gray-300 rounded-md p-1 text-md font-thin" type="text"></input></th>
            </tr>

        }

    }

    const availableSearch = () => {
        if (selectedModel == "customer")
            return 'Enter a "Customer Name / Company name" in QuickBooks to retrieve data to aid with the mapping.'

        if (selectedModel == "product")
            return 'Enter an "Item Name / Number" in QuickBooks to retrieve data to aid with the mapping.'

        if (selectedModel == "order")
            return 'Enter a "Sales Order Number" in Fora to retrieve data to aid with the mapping.'

        return ""
    }

    return (
        <>
            <div id="error-message" className={`fixed top-0 left-0 right-0 bg-yellow-100 border-t-4 border-yellow-500 p-4 z-50 ${errorMessage ? "" : "hidden"}`}>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="text-yellow-700 font-bold">Notice !</p>
                        <p className="text-yellow-600">{errorMessage}</p>
                    </div>
                    <button id="close-error" className="text-red-600 hover:text-yellow-800" onClick={e => { setErrorMessage(""); }}>
                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.293 5.293a1 1 0 011.414 1.414L11.414 11l4.293 4.293a1 1 0 11-1.414 1.414L10 12.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 11 4.293 6.707a1 1 0 111.414-1.414L10 9.586l4.293-4.293z" /></svg>
                    </button>
                </div>
            </div>
            <div className="min-h-full">
                <TopNavbar />
                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 flex items-center">
                        {df ? <>
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 ml-4">Default mapping</h1>
                        </> : <>
                            <a href={`/?platform=${selectedPlatform}`} >
                                <p className="text-xl font-thin tracking-tight text-gray-900 ml-4">Dashboard</p>
                            </a>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 ml-4">Mapping</h1>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                            <a href={selectedPlatform == "quickbooks_desktop" ? "/jobs/desktop" : "/jobs"} className="text-xl font-thin tracking-tight text-gray-900 ml-4">Run</a>
                        </>}
                    </div>
                </header>
                <main className="mb-8">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <ul className="flex text-center w-full gap-8">
                            <li className="mr-2">
                                <button onClick={e => { if (!loading) setSelectedModel("customer") }}
                                    className={`inline-flex p-4 text-gray-600 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${selectedModel == "customer" ? "border-gray-600" : ""}`}>
                                    {loading && selectedModel == "customer" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2 animated-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> : <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd">
                                            </path>
                                        </svg>}
                                    Customer
                                </button>
                            </li>
                            <li className="mr-2">
                                <button onClick={e => { if (!loading) setSelectedModel("product") }}
                                    className={`inline-flex p-4 text-gray-600 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${selectedModel == "product" ? "border-gray-600" : ""}`} aria-current="page">
                                    {loading && selectedModel == "product" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2 animated-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> : <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                        </svg>}
                                    Product
                                </button>
                            </li>
                            <li className="mr-2">
                                <button onClick={e => { if (!loading) setSelectedModel("order") }}
                                    className={`inline-flex p-4 text-gray-600 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${selectedModel == "order" ? "border-gray-600" : ""}`}>
                                    {loading && selectedModel == "order" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2 animated-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> : <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                                            <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>
                                        </svg>}
                                    Order
                                </button>
                            </li>
                            {/* <li className="mr-2">
                            <button onClick={e => { if (!loading) setSelectedModel("order_product") }}
                                className={`inline-flex p-4 text-gray-600 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${selectedModel == "order_product" ? "border-gray-600" : ""}`} aria-current="page">
                                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                </svg>
                                Order product
                            </button>
                        </li> */}
                        </ul>
                        <div className="bg-slate-100 shadow-lg rounded-lg px-4 py-2 flex items-center justify-between mt-2">
                            <div className="w-full justify-between items-center flex">
                                <input onChange={e => { if (!loading) setFilterData(e.target.value) }} value={filterData} placeholder={availableSearch()} className="w-2/3 bg-white border-2 border-gray-300 rounded-lg p-1" type="text"></input>
                                <div className="w-1/3 flex justify-start items-center">

                                    <button onClick={e => { if (!searching) searchSample() }} className={`flex ml-4 text-xs ${!searching ? "bg-gray-50 hover:bg-gray-100" : "bg-gray-300 hover:bg-gray-300"} border-2 border-gray-500 text-gray-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}>
                                        {searching ? <> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-2 animated-spin">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> Searching </> : <><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg>
                                            Search </>}
                                    </button>
                                    <div className="flex justify-start items-left flex-col">
                                        <p className="font-bold ml-4 text-xs flex">
                                            {foraWorks ?
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-green-500 mr-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>

                                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500 mr-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                </svg>
                                            }
                                            Fora {foraCompany && foraCompany.name ?  '"' + foraCompany.name + '"' : account && account.user ? '"' + account.user.Name + '"' : null}

                                        </p>

                                        {selectedPlatform == "quickbooks_desktop" ? <p className="font-bold ml-4 text-xs flex">

                                            {QBDCompany ? <><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-green-500 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>QuickBooks "{QBDCompany.companyName}"</> : <>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500 mr-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                </svg> QuickBooks</>
                                            }

                                        </p> : null}

                                        {selectedPlatform == "quickbooks_online" ? <p className="font-bold ml-4 text-xs flex">

                                            {QBOCompany ? <><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-green-500 mr-1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>QuickBooks "{QBOCompany.CompanyName}"</> : <>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500 mr-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                                </svg> QuickBooks</>
                                            }

                                        </p> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
                        <div className="w-full bg-slate-100 rounded ring-1 ring-slate-300">
                            <table className="text-left w-full">
                                <thead className="h-[50px] bg-slate-200 flex w-full">
                                    {tableHeader(!selectedModel.startsWith('order'))}
                                </thead>
                                <tbody className="bg-grey-light items-center justify-start overflow-y-scroll w-full h-[500px] flex flex-col ">
                                    {
                                        Object.keys((keyValues)).filter((item) => toUserFriendlyText(item).toLowerCase().includes(!selectedModel.startsWith('order') ? toUserFriendlyText(filterValues).toLowerCase() : toUserFriendlyText(filterValues2).toLowerCase())).map((k) => (
                                            <tr key={`source.${k}`} id={`source.${k}`} className={`cursor-pointer flex w-full ${k == source ? "bg-blue-200" : ""}`} onClick={e => { if (!loading) setSource(source == k ? "" : k) }}>
                                                <td className="px-4 py-2 text-xs w-full">

                                                    <span className="w-full flex justify-between">
                                                        <p className={`text-left ${highlightSelected(k, "source") !== -1 ? "bg-green-200" : ""} `}>{toUserFriendlyText(k)}</p>
                                                        <p className="text-right font-bold text-green-500 truncate">{getSample(k)}</p>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                                <tfoot>
                                    <tr className="text-center h-16 bg-slate-200">
                                        <td>
                                            <p className="text-md font-bold"> {toUserFriendlyText(source)} </p>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="w-full bg-slate-100 rounded ring-1 ring-slate-300">
                            <table className="text-left w-full">
                                <thead className="h-[50px] bg-slate-200 flex w-full">
                                    {tableHeader(selectedModel.startsWith('order'))}
                                </thead>
                                <tbody className="bg-grey-light items-center justify-start overflow-y-scroll w-full h-[500px] flex flex-col ">
                                    {
                                        Object.keys((keyValues2)).filter((item) => toUserFriendlyText(item).toLowerCase().includes(selectedModel.startsWith('order') ? toUserFriendlyText(filterValues).toLowerCase() : toUserFriendlyText(filterValues2).toLowerCase())).map((k) => (
                                            <tr key={`destination.${k}`} id={`destination.${k}`} className={`cursor-pointer flex w-full ${k == destination ? "bg-blue-200" : ""}`} onClick={e => { if (!loading) setDestination(destination == k ? "" : k) }}>
                                                <td className="px-4 py-2 text-xs w-full">

                                                    <span className="w-full flex justify-between">
                                                        <p className={`text-left ${highlightSelected(k, "destination") !== -1 ? "bg-green-200" : ""} `}>{toUserFriendlyText(k)}</p>
                                                        <p className="text-right font-bold text-green-500 truncate">{getSample(k)}</p>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="w-full h-16 bg-slate-200 flex items-center justify-center">
                                            <p className="text-md font-bold"> {toUserFriendlyText(destination)}</p>
                                            {destination && !source ?
                                                <span className="flex items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="ml-1 mr-1 w-2 h-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                                    </svg>
                                                    <input type="text" onChange={e => { if (!loading) setValue(e.target.value) }} value={value} className="border rounded-l p-1 text-xs" placeholder="Enter value"></input>
                                                </span> : null}
                                            {(destination && source) || (destination && value) ?
                                                <button className="ml-2 text-xs bg-gray-50 hover:bg-gray-100 text-gray-500 border-2 border-gray-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={e => addResult()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                </button> : null}


                                        </td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="w-full bg-slate-100 rounded ring-1 ring-slate-300 overflow-scroll">
                            <table className="w-full">
                                <thead className="h-[50px] bg-slate-200 flex w-full">
                                    <tr className="w-full">
                                        <th className="px-2 py-1 text-xs">Mapping Result</th>
                                        <th className="px-2 py-1 w-full"><input placeholder="Field searching..." onChange={e => { if (!loading) setFilterValues3(e.target.value) }} value={filterValues3} className="w-full bg-white border-2 border-gray-300 rounded-md p-1 text-md font-thin" type="text"></input></th>
                                    </tr>
                                </thead>
                                <tbody id="resultContainer" className="bg-grey-light items-center justify-start overflow-y-scroll w-full h-[500px] flex flex-col ">
                                    {customConfig.filter((item) => !item.source ? true : toUserFriendlyText(item.source).toLowerCase().includes(toUserFriendlyText(filterValues3).toLowerCase()) || toUserFriendlyText(item.destination).toLowerCase().includes(toUserFriendlyText(filterValues3).toLowerCase())).map((k, i) => (
                                        <tr key={`config-${i}`} id={`config-${i}`} className="flex w-full border-b-2">
                                            <td className="p-1">

                                                <button onClick={e => {
                                                    if (window.confirm(`Do you want to remove "${toUserFriendlyText(k.source)} - ${toUserFriendlyText(k.destination)}"?`)) {
                                                        var config = customConfig.filter(item => !(item.source == k.source && item.destination == k.destination && item.value == k.value));
                                                        if (!loading) setCustomConfig(config)
                                                    }
                                                }} type="button" className="justify-center text-xs bg-gray-50 hover:bg-gray-100 text-red-500 border-2 border-red-500 font-bold py-1 px-1 rounded focus:outline-none focus:shadow-outline">
                                                    Remove
                                                </button>

                                            </td>
                                            <td className="p-1 text-xs">
                                                <p className="text-left flex flex-row justify-start">
                                                    {toUserFriendlyText(k.source)}
                                                </p>
                                                <p className="text-left flex flex-row justify-start">
                                                    {toUserFriendlyText(k.destination)}
                                                </p>
                                                <p className="text-left flex flex-row justify-start font-bold text-green-500 truncate">
                                                    {getSample(k.source)}
                                                </p>
                                            </td>
                                        </tr>
                                    ))
                                    }

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-center h-16 bg-slate-200">
                                            <button onClick={e => { if (!loading) clearConfig() }} className={`mr-2 text-xs bg-gray-50 hover:bg-gray-100 text-red-500 border-2 border-red-500 font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline`}>Remove All</button>
                                            {df ? null : <button onClick={e => { if (!loading) loadDefaultConfig() }} className={`mr-2 text-xs bg-gray-50 hover:bg-gray-100 border-2 border-gray-500 text-gray-500 font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline`}>Load default config</button>}
                                            <button onClick={e => { if (!loading) saveConfig() }} className={`text-xs bg-gray-50 hover:bg-gray-100 border-2 border-green-500 text-green-500 font-bold py-2 px-2 rounded focus:outline-none focus:shadow-outline`}>Save</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </main >
            </div >
        </>
    )
}