import React from "react"
import { ArrowLeftOnRectangleIcon, Bars3Icon, BellIcon, EnvelopeIcon, LinkIcon, UserCircleIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Menu, Transition } from '@headlessui/react'
import * as Account from '../account';
export default function TopNavbar(props) {

    const [isMobileMenuPresented, setIsMobileMenuPresented] = React.useState(false)

    const links = [
        // { href: '/account-settings', label: 'Account settings', icon: <UserCircleIcon className='w-4 h-4' /> },
        // { href: '/support', label: 'Support', icon: <EnvelopeIcon className='w-4 h-4' /> },
        { href: '/signout', label: 'Sign out', icon: <ArrowLeftOnRectangleIcon className='w-4 h-4' /> },
    ]

    return (
        <nav className="bg-gray-800">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 flex items-center">
                            <a href="/" className="flex flex-row">
                                <LinkIcon className="w-6 h-6 text-white" />
                                <p className='text-white font-bold ml-2'>Connector v.1.4.0</p>
                            </a>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                                {/* <a href="#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" aria-current="page">Dashboard</a>
                                <a href="/jobs" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Jobs</a>
                                <a href="/mapping" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Settings</a> */}

                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                            {/* <button type="button" className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">View notifications</span>
                                <BellIcon className='w-5 h-5' />
                            </button> */}

                            {/* <!-- Profile dropdown --> */}
                            {Account.getUserAccount() && Account.getUserAccount().user ?
                                <div className="flex items-center">
                                    <p className="text-white font-bold mr-2">{Account.getUserAccount() && Account.getUserAccount().user && Account.getUserAccount().user.Name} </p>
                                    <button className="text-red-500 border-2 border-red-500  rounded-lg px-2 py-2 font-bold" onClick={_ => window.location.href = "/signout"}>Sign out</button>
                                </div>

                                : <button className="text-white border-2 border-white rounded-lg px-4 py-2 mr-2" onClick={_ => window.location.href = "/signin"}>Sign in</button>}
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        {/* <!-- Mobile menu button --> */}
                        <button onClick={() => { setIsMobileMenuPresented(!isMobileMenuPresented) }} type="button" className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            {
                                isMobileMenuPresented ?
                                    <XMarkIcon className='w-6 h-6' />
                                    :
                                    <Bars3Icon className='w-6 h-6' />
                            }



                        </button>
                    </div>
                </div>
            </div>

            {/* <!-- Mobile menu, show/hide based on menu state. --> */}
            <Transition
                show={isMobileMenuPresented}
                enter="ease transition transform duration-100 ease-out"
                enterFrom="-translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform opacity-0"
            >



                <div className="md:hidden absolute bg-gray-800 w-full" id="mobile-menu">

                    <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                        {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                        {/* <a href="#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Dashboard</a>

                        <a href="#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Jobs</a>

                        <a href="/mapping" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Settings</a> */}


                    </div>
                    <div className="border-t border-gray-700 pt-4 pb-3">

                        <div className="px-2 flex flex-col">
                            
                        {Account.getUserAccount() && Account.getUserAccount().user ?
                                <div className="flex items-center">
                                    <p className="text-white font-bold mr-2 w-full">{Account.getUserAccount() && Account.getUserAccount().user && Account.getUserAccount().user.Name} </p>
                                    <button className="text-red-500 border-2 border-red-500  rounded-lg px-2 py-2 font-bold w-full" onClick={_ => window.location.href = "/signout"}>Sign out</button>
                                </div>

                                : <button className="text-white border-2 border-white rounded-lg px-4 py-2 mr-2 w-full" onClick={_ => window.location.href = "/signin"}>Sign in</button>}


                        </div>
                    </div>
                </div>
            </Transition>
        </nav>
    )
}