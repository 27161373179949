import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as Account from '../../account';
import * as api from '../../services/api'
import { sortOnKeys, locale } from "../../utils";

export default function Logs(props) {

    const [data, setData] = useState([])

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const platform = params.get('platform');
    const status = params.get('status');

    const [filter, setFilter] = useState({
        platform: platform ? platform : "",
        status: status ? status : "",
    })

    useEffect(() => {



        logs(filter)
    }, [])


    const logs = (data) => {
        api.logs(data)
            .then(response => {
                setData(response)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const parseResult = (item) => {
        var data = JSON.parse(item)

        if (!data) return "";

        var result = data.result

        if (!result) return "";


        if (result.synced && Array.isArray(result.synced))
            return result.synced.map(item => "Fora " + (item.ForaOrder || item.Quote || item.Order)  
        + (item.QBOrder ? " to QuickBooks " + item.QBOrder.DocNumber : (item.Error ? " " + item.Error.error_message : ""))

        ).join('\n\n')

        if (result.fora_response) {
            try {
                return JSON.parse(result.fora_response).Message
            } catch {
                return result.fora_response
            }
        }
        
        return result.error_message
    }


    function formatDate(dbDateString) {

        // Create a new Date object with the database date string
        const dbDate = new Date(dbDateString);

        // Get the machine timezone offset in minutes
        const timezoneOffset = new Date().getTimezoneOffset();

        // Convert the timezone offset to milliseconds
        const timezoneOffsetMs = timezoneOffset * 60 * 1000;

        // Get the machine date by adding the timezone offset to the database date
        const machineDate = new Date(dbDate.getTime() - timezoneOffsetMs);

        // Format the machine date as a string in the machine timezone
        return machineDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC',
            hour12: false
        })
    }

    function handleOptionChange(e) {
        var { name, value } = e.target
        setFilter({ ...filter, [name]: value })
    }

    useEffect(() => {
        logs(filter)
    }, [filter])

    return (
        <div className="min-h-full">
            <TopNavbar />
            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 flex items-center">
                    <a href={`/?platform=${filter.platform}`} >
                        <p className="text-xl font-thin tracking-tight text-gray-900 ml-4">Dashboard</p>
                    </a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <a href={`/mapping?platform=${filter.platform}`} className="text-xl font-thin tracking-tight text-gray-900 ml-4">Mapping</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <a href={filter.platform == "quickbooks_desktop" ? "/jobs/desktop" : "/jobs"}  className="text-xl font-thin tracking-tight text-gray-900 ml-4">Run</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 ml-4">Logs</h1>
                </div>
            </header>
            <main className="container mx-auto px-4 w-full items-center justify-center">
                <div className="min-h-screen">
                    <div className="max-w-4xl mx-auto py-4">
                        <div className="space-y-6 w-full">
                            <div className="bg-white shadow-lg rounded-lg px-6 py-4 flex items-center justify-between">
                                <div className="w-full">
                                    <span className="flex flex-row items-center">
                                        <h2 className="font-bold text-gray-900">Filter</h2>
                                    </span>
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="relative m-2">
                                            <select value={filter.platform} onChange={handleOptionChange} name="platform" className="w-full appearance-none bg-white border-2 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" id="select-option">
                                                <option value="">All platform</option>
                                                <option value="quickbooks_online">QuickBooks Online</option>
                                                <option value="quickbooks_desktop">QuickBooks Desktop</option>
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M10 12l-5-5 1.41-1.41L10 9.17l3.59-3.58L15 7z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="relative m-2">
                                            <select value={filter.status} onChange={handleOptionChange} name="status" className="w-full appearance-none bg-white border-2 border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" id="select-option">
                                                <option value="">All status</option>
                                                <option value="OK">OK</option>
                                                <option value="ERROR">ERROR</option>
                                            </select>
                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M10 12l-5-5 1.41-1.41L10 9.17l3.59-3.58L15 7z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="bg-white shadow-lg rounded-lg px-6 py-4 items-center justify-between">
                                <div className="mt-4 overflow-x-auto">
                                    <table className="divide-y divide-gray-200 table-fixed w-full">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {/* <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                    Platform
                                                </th> */}
                                                <th scope="col" className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                    Job
                                                </th>
                                                <th scope="col" className="w-3/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                    Result
                                                </th>
                                                <th scope="col" className="w-2/6 px-6 py-3 text-left text-xs font-medium text-gray-500">
                                                    Succeeded At
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">

                                            {data && data.map((k) => (
                                                <tr key={k.id}>
                                                    {/* <td className="px-6 py-4">
                                                        <div className="text-xs text-gray-900">
                                                            {locale(JSON.parse(k.data).platform)}
                                                        </div>
                                                    </td> */}
                                                    <td className="px-6 py-4">
                                                        <div className="text-sm text-gray-900">
                                                            {locale(JSON.parse(k.data).job)}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`items-start text-xs ${JSON.parse(k.data).status == "OK" ? "text-green-600" : "text-red-600"} flex`}>{JSON.parse(k.data).status}<p className="ml-2 text-gray-900 truncate whitespace-pre-line">{parseResult(k.data)}</p></span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-pre-line">
                                                        <div className="text-xs text-gray-900">{formatDate(k.createdAt)}</div>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div >
    )
}