import React, { useState, useMemo, useEffect } from "react"
import TopNavbar from "../../shares/TopNavbar"
import * as api from '../../services/auth'
import * as Account from '../../account';

export default function Signin(props) {

    const [data, setData] = useState({
        companyLoginName: "",
        companyLoginPassword: "",
    })

    const handleSignIn = (e) => {
        e.preventDefault();
        api.signin(data)
            .then(response => {
                Account.updateAccount(JSON.parse(response))
                window.location.href = "/"

            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        setData({ ...data, [name]: value })
    }


    return (

        <div className="min-h-full">
            <TopNavbar />

            <header className="bg-white shadow">
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900">Signin</h1>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 justify-center">
                    <form className="flex flex-col gap-2 justify-center" onSubmit={handleSignIn}>
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="companyLoginName" className="sr-only">Company Login Name</label>
                                <input id="companyLoginName" name="companyLoginName" type="text" autoComplete="companyLoginName" required onChange={handleDataChange} value={data.companyLoginName}
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Company Login Name" />
                            </div>
                            <div>
                                <label htmlFor="companyLoginPassword" className="sr-only">Password</label>
                                <input id="companyLoginPassword" name="companyLoginPassword" type="password" autoComplete="current-companyLoginPassword" required onChange={handleDataChange} value={data.companyLoginPassword}
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Password" />
                            </div>
                        </div>
                        <div>
                            <button id="signin-button" disabled={data.companyLoginName.trim().length === 0 || data.companyLoginPassword.trim().length === 0} type="submit" className="group relative bg-gray-50 border-2 border-gray-500 text-gray-500 font-bold rounded-lg px-2 py-2 w-full disabled:opacity-50">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                    <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    )
}